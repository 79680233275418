* {
  font-family: "Montserrat", sans-serif;
}

a {
  color: #51788F;
  text-decoration: underline;
}

p:not(:last-of-type) {
  line-height: 1.5;
  margin: 0 0 20px 0;
}

h1 {
  color: #353E48;
  font-size: 30px;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  margin: 0 0 20px 0;
}

h2 {
  margin: 0 0 20px 0;
  font-size: 24px;
  color: #83838B;
  text-transform: uppercase;
  font-weight: 600;
}

h3 {
  margin: 0 0 20px 0;
  color: #353E48;
  font-size: 16px;
  font-weight: 700;
}

h4, h5, h6 {
  margin: 0 0 15px 0;
  color: #353E48;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
}

ul {
  padding-left: 0;
}

strong {
  font-weight: 700;
}

em {
  font-style: italic;
}

.btn-button > a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  background: #51788F;
  padding: 10px 20px;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
}
.domain-savoie .btn-button > a {
  background-color: #F8BE33;
}
.domain-vivarais .btn-button > a {
  background-color: #89ba17;
}
.domain-grenoble .btn-button > a {
  background-color: #E97422;
}
.domain-arve .btn-button > a {
  background-color: #259EBB;
}
.domain-nord .btn-button > a {
  background-color: #CE1655;
}
.domain-viva5 .btn-button > a {
  background-color: #89ba17;
}
.domain-lac .btn-button > a {
  background-color: #2D91C9;
}
.domain-savoiehautesavoie .btn-button > a {
  background-color: #00AFCB;
}

.field-name-body ul {
  margin: 0 0 20px 20px;
}
.field-name-body li {
  margin: 5px 0 5px 0;
}

ul + p {
  margin-top: 20px;
}

/*table*/
table th,
table td,
table tr,
table thead,
table tbody,
table {
  border-right: none;
  border-left: none;
}

table {
  border-color: gray;
}

table td {
  padding: 5px 10px;
}

/*----------*/
/*gestion des couleurs*/
/*----------/*


/*COMMUN*/
/*texte */
.reso-texte {
  color: #4c4b53;
}

.reso-texte-lien-1 {
  color: #94284a;
}

.reso-texte-lien-2 {
  color: #e74d51;
}

.reso-texte-gris-3 {
  color: #7e8390;
}

.reso-texte-gris-4 {
  color: #5c5c67;
}

.reso-texte-blanc {
  color: white;
}

.reso-texte-beige-2 {
  color: #cfccca;
}

.reso-link-lien-1 {
  color: #94284a;
}

.reso-link-lien-2 {
  color: #e74d51;
}

/*background*/
.reso-bg-lien-1 {
  background: #94284a;
  padding: 15px;
  color: #fff;
  margin-bottom: 15px;
}

.reso-bg-gris-1 {
  background: #d4d4d8;
  padding: 15px;
  margin-bottom: 15px;
}

.reso-bg-gris-3 {
  background: #7e8390;
  padding: 15px;
  color: #fff;
  margin-bottom: 15px;
}

.reso-bg-gris-4 {
  background: #5c5c67;
  padding: 15px;
  color: #fff;
  margin-bottom: 15px;
}

.reso-bg-blanc {
  background: white;
  padding: 15px;
  margin-bottom: 15px;
}

.reso-bg-beige-1 {
  background: #e8e7e5;
  padding: 15px;
  margin-bottom: 15px;
}

.reso-bg-beige-2 {
  background: #cfccca;
  padding: 15px;
  margin-bottom: 15px;
}

/*SPECIFIQUE*/
/*texte*/
.acad-texte-rose-2 {
  color: #b42555;
}

/*background*/
.acad-bg-rose-1-logo {
  background: #da2a5c;
  padding: 15px;
  margin-bottom: 15px;
  color: #fff;
}

.acad-bg-rose-2 {
  background: #b42555;
  padding: 15px;
  margin-bottom: 15px;
  color: #fff;
}

/*Greta Grenoble */
/* #ec7404*/
body.domain-grenoble .encart-couleur {
  background: #ec7404;
}

/*Greta Arve */
/* #0d9ebd*/
body.domain-arve .encart-couleur {
  background: #0d9ebd;
}

/*Greta Lac*/
/*#2290cb*/
body.domain-lac .encart-couleur {
  background: #2290cb;
}

/*Greta SavoieHauteSavoie*/
/*#2290cb*/
body.domain-savoiehautesavoie .encart-couleur {
  background: #2290cb;
}

/*Greta Savoie */
/* #fac016*/
body.domain-savoie .encart-couleur {
  background: #fac016;
}

/*Greta Nord Isere*/
/*#d30454*/
body.domain-nord .encart-couleur {
  background: #d30454;
}

/*Greta VIVA*/
/* #a5037b */
body.domain-viva5 .encart-couleur {
  background: #a5037b;
}

/*Greta Vivarais Provence */
/*#7db35d*/
body.domain-vivarais .encart-couleur {
  background: #7db35d;
}

div.view-actualites div.views-row {
  margin-top: 15px;
}