@import "variables";

* {
	font-family: $fontalt;
}

a {
	color: $color-1;
	text-decoration: underline;
}

p:not(:last-of-type) {
	line-height: 1.5;
	margin: 0 0 20px 0;
}

h1 {
	color: $color-3;
	font-size: 30px;
	border: none;
	text-transform: uppercase;
	font-weight: 600;
	margin:0 0 20px 0;
}

h2 {
	margin: 0 0 20px 0;
	font-size: 24px;
	color: $color-2;
	text-transform: uppercase;
	font-weight: 600;
}

h3 {
	margin: 0 0 20px 0;
	color: $color-3;
	font-size: 16px;
	font-weight: 700;
}

h4, h5, h6 {
	margin: 0 0 15px 0;
	color: $color-3;
	font-size: 14px;
	text-transform: uppercase;
	padding: 0;
}


ul {
	padding-left: 0;
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

.btn-button > a {
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 14px;
	background: $color-1;
	padding: 10px 20px;
	text-decoration: none;
	display: inline-block;
	border-radius: 2px;

	.domain-savoie    & { background-color: $color-savoie; }
	.domain-vivarais  & { background-color: $color-provence; }
	.domain-grenoble  & { background-color: $color-grenoble; }
	.domain-arve      & { background-color: $color-arve; }
	.domain-nord      & { background-color: $color-nordisere; }
	.domain-viva5     & { background-color: $color-viva5; }
	.domain-lac       & { background-color: $color-lac; }
  .domain-savoiehautesavoie       & { background-color: $color-savoiehautesavoie; }
}

.field-name-body {
	ul {
		margin: 0 0 20px 20px;
	}

	li {
		margin: 5px 0 5px 0;
	}
}

ul + p {
	margin-top: 20px;
}

/*table*/

table th,
table td,
table tr,
table thead,
table tbody,
table {
	border-right: none;
	border-left: none;
}

table {
	border-color: gray;
}

table td {
	padding: 5px 10px;
}

/*----------*/
/*gestion des couleurs*/
/*----------/*


/*COMMUN*/
/*texte */
.reso-texte {
	color: rgb(76,75,83);
}

.reso-texte-lien-1 {
	color: rgb(148,40,74);
}

.reso-texte-lien-2 {
	color: rgb(231,77,81);
}

.reso-texte-gris-3 {
	color: rgb(126,131,144);
}

.reso-texte-gris-4 {
	color: rgb(92,92,103);
}

.reso-texte-blanc {
	color: rgb(255,255,255);
}

.reso-texte-beige-2 {
	color: rgb(207,204,202);
}

.reso-link-lien-1 {
	color: rgb(148,40,74);
}

.reso-link-lien-2 {
	color: rgb(231,77,81);
}


/*background*/

.reso-bg-lien-1 {
	background: rgb(148,40,74);
	padding: 15px;
	color: #fff;
	margin-bottom: 15px;
}

.reso-bg-gris-1 {
	background: rgb(212,212,216);
	padding: 15px;
	margin-bottom: 15px;
}

.reso-bg-gris-3 {
	background: rgb(126,131,144);
	padding: 15px;
	color: #fff;
	margin-bottom: 15px;
}

.reso-bg-gris-4 {
	background: rgb(92,92,103);
	padding: 15px;
	color: #fff;
	margin-bottom: 15px;
}

.reso-bg-blanc {
	background: rgb(255,255,255);
	padding: 15px;
	margin-bottom: 15px;
}

.reso-bg-beige-1 {
	background: rgb(232,231,229);
	padding: 15px;
	margin-bottom: 15px;
}

.reso-bg-beige-2 {
	background: rgb(207,204,202);
	padding: 15px;
	margin-bottom: 15px;
}


/*SPECIFIQUE*/

/*texte*/
.acad-texte-rose-2 {
	color: rgb(180,37,85);
}

/*background*/
.acad-bg-rose-1-logo {
	background: rgb(218,42,92);
	padding: 15px;
	margin-bottom: 15px;
	color: #fff;
}

.acad-bg-rose-2 {
	background: rgb(180,37,85);
	padding: 15px;
	margin-bottom: 15px;
	color: #fff;
}


/*Greta Grenoble */
/* #ec7404*/
body.domain-grenoble .encart-couleur {
	background: #ec7404;
}

/*Greta Arve */
/* #0d9ebd*/
body.domain-arve .encart-couleur {
	background: #0d9ebd;
}

/*Greta Lac*/
/*#2290cb*/
body.domain-lac .encart-couleur {
  background: #2290cb;
}
/*Greta SavoieHauteSavoie*/
/*#2290cb*/
body.domain-savoiehautesavoie .encart-couleur {
  background: #2290cb;
}
/*Greta Savoie */
/* #fac016*/
body.domain-savoie .encart-couleur {
	background: #fac016;
}

/*Greta Nord Isere*/
/*#d30454*/
body.domain-nord .encart-couleur {
	background: #d30454;
}

/*Greta VIVA*/
/* #a5037b */
body.domain-viva5 .encart-couleur {
	background: #a5037b;
}

/*Greta Vivarais Provence */
/*#7db35d*/
body.domain-vivarais .encart-couleur {
	background: #7db35d;
}

div.view-actualites div.views-row {
  margin-top: 15px
}
